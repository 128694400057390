<section class="beginner-container">
    <div class="circuloImg"></div>
    <img class="esfera" src="../../../../../assets/img/beginner/esfera.png" alt="">
    <div class="ciculoAmarillo"></div>
    <img class="sigsag" src="../../../../../assets/img/beginner/sigsag.png" alt="">
    <img class="flecha" src="../../../../../assets/img/beginner/flecha.png" alt="">
    <img class="flechaRoja" src="../../../../../assets/img/beginner/flechaRoja.png" alt="">
    <img class="cruzAmarilla" src="../../../../../assets/img/beginner/cruzAmarilla.png" alt="">
    <img class="puntosBlancos" src="../../../../../assets/img/beginner/puntosBlancos.png" alt="">
    <div class="containerTexto">
        <h2 class="titulo">Nivel Básico A1, A2 (SPEAKING)</h2>
        <p class="texto"> Pronunciación y Entonación, <br> <span class="texto2">Aprende e Interactúa con Vocabulario en Inglés</span></p>
        <!-- <li class="texto">Pronunciación y Entonación</li>
        <li class="texto">Aprende e Interactúa con Vocabulario en Inglés</li> -->
    </div>
    <div class="redes">
        <a href="https://www.facebook.com/MobilEnglishGuadalajara/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook"></i></a>
    </div>

</section>