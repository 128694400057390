import { NgModule } from '@angular/core';


import { CommonModule } from '@angular/common';
import { NivelComponent } from './nivel.component';
import { BegginerComponent } from './begginer/begginer.component';
import { IntermediateComponent } from './intermediate/intermediate.component';
import { AdvancedComponent } from './advanced/advanced.component';
import { NivelRoutingModule } from './nivel-routing.module';

@NgModule({
    declarations: [
        NivelComponent,
        BegginerComponent,
        IntermediateComponent,
        AdvancedComponent,
    ],
    imports: [ CommonModule,
               NivelRoutingModule ],
    exports: [
        NivelComponent,
        BegginerComponent,
        IntermediateComponent,
        AdvancedComponent,
    ],
    providers: [],
})
export class NivelModule {

}