<div class="contenedor footer-A"> 
    <footer class="container-fluid">
        <img src="../../../assets/img/Recurso2.png" alt="" class="imgContacto">
        <div class="row justify-content-around">
            <div class="col-12 col-md-3 mt-5">
                <div class="contacto">
                    <h4>Contacto</h4>
                    <div class="datosContacto text-center text-md-left mt-5">
                        <p><i class="fa fa-phone-square"></i> Teléfono: <span class="azul">33 1789 1379</span></p>
                        <p><i class="fa fa-envelope"></i><span class="mr-2"> Correo:  </span> <img src="../../../assets/img/correo.png" alt=""></p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mt-5">
                <div class="ubicacionFooter">
                    <h4>Ubicación</h4>
                    <div class="ubicacionF text-center text-md-left mt-5">
                        <p><i class="fa fa-map-pin"></i> Calle: <span class="azul">Francisco Márquez #464</span></p>
                        <p><i class="fa fa-map-pin"></i> Entre las calles <span class="azul"> Jesús Amaya Topete y Africa</span></p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mt-5">
                <div class="redes">
                    <h4>Social</h4>
                    <div class="social text-center text-lg-left mt-5">
                        <p><i class="fa fa-facebook-official"></i><a class="facebook" href="https://www.facebook.com/MobilEnglishGuadalajara/" target="_blank"> @MOBILENGLISHGUADALAJARA</a></p>
                        <p><i class="fa fa-whatsapp"></i><span class="azul"> 33 1789 1379</span></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>