import { Component, OnInit } from '@angular/core';
import Swal from '../../../../assets/js/sweetalert2';

@Component({
  selector: 'app-metodologia',
  templateUrl: './metodologia.component.html',
  styleUrls: ['./metodologia.component.sass']
})
export class MetodologiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  textAlert(){
    Swal.fire({
      allowOutsideClick: true,
      type: 'info',
      icon: 'info',
      title: 'Teléfono: 33 - 1789 - 1379'
    });
  }
}
