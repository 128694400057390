import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.sass']
})
export class AdvancedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
