<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
      <img src="../../../assets/img/MOBILE-B3.png" alt="" class="logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item" routerLinkActive = 'active'>
          <a class="nav-link" routerLink = '/home'>Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" routerLinkActive = 'active'>
          <a class="nav-link" routerLink = '/nivel'>Niveles <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" routerLinkActive = 'active'>
          <a class="nav-link" routerLink = '/nivel/begginer'>Beginner <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" routerLinkActive = 'active'>
          <a class="nav-link" routerLink = '/nivel/intermediate'>Intermediate <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" routerLinkActive = 'active'>
          <a class="nav-link" routerLink = '/nivel/advanced'>Advanced <span class="sr-only">(current)</span></a>
        </li>
      </ul>
      <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
      </form> -->
    </div>
</nav>
