<section class="contenedor">
    <div class="contenedorCirculoImg">
        <img class="circuloBlanco" src="../../../../../assets/img/intermediate/circuloBlanco.png" alt="">
        <div class="circuloImg"></div>
    </div>
    <img class="trianguloS1" src="../../../../../assets/img/intermediate/triangulo.png" alt="">
    <img class="trianguloS2" src="../../../../../assets/img/intermediate/triangulo.png" alt="">
    <img class="trianguloI" src="../../../../../assets/img/intermediate/triangulo.png" alt="">
    <img class="puntosBlancos puntosBlancosS" src="../../../../../assets/img/beginner/puntosBlancos.png" alt="">
    <img class="puntosBlancos puntosBlancosI" src="../../../../../assets/img/beginner/puntosBlancos.png" alt="">
    <img class="linea linea1" src="../../../../../assets/img/intermediate/linea.png" alt="">
    <img class="linea linea2" src="../../../../../assets/img/intermediate/linea.png" alt="">
    <img class="linea linea3" src="../../../../../assets/img/intermediate/linea.png" alt="">

    <div class="contenedorTexto">
        <h2 class="titulo">NIVEL INTERMEDIO B1, B2 (READING/WRITING)</h2>
        <p class="texto">Mejora tu lectura, Escritura y Fluidez</p>
        <a href="https://www.facebook.com/MobilEnglishGuadalajara/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook redes"></i></a>
    </div>
    
</section>
