<section class="row main">
    <div class="col-12 p-0 align-content-center">
        <div class="carousel slide carousel-fade" id="principal-carousel" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#principal-carousel" data-slide-to="0" class="active"></li>
                <li data-target="#principal-carousel" data-slide-to="1"></li>
                <li data-target="#principal-carousel" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item carrete">
                    <img src="../../../../assets/img/imagen10_1.jpg" alt="" class="flex-wrap">
                </div>
                <div class="carousel-item carrete active">
                    <img src="../../../../assets/img/imagen9_1.png" alt="">
                </div>
                <div class="carousel-item carrete">
                    <img src="../../../../assets/img/imagen7_1.png" alt="">
                </div>
            </div>
            <a href="#principal-carousel" class="carousel-control-prev" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Anterior</span>
            </a>
            <a href="#principal-carousel" class="carousel-control-next" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Siguiente</span>
            </a>
        </div>
    </div>
</section>
