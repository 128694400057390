import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.sass']
})
export class TarjetasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
