<div class="contenedorAdvance">
    <img class="circuloAzul" src="../../../../../assets/img/advanced/circuloAzul.png" alt="">
    <img class="circuloRojo" src="../../../../../assets/img/advanced/circuloRojo.png" alt="">
    <img class="mitadCirculoAzul mitadCirculoAzul1" src="../../../../../assets/img/advanced/middlecircle.png" alt="">
    <img class="mitadCirculoAzul mitadCirculoAzul2" src="../../../../../assets/img/advanced/middlecircle.png" alt="">
    <img class="mitadCirculoAzul mitadCirculoAzul3" src="../../../../../assets/img/advanced/middlecircle.png" alt="">
    <img class="mediaLuna mediaLuna1" src="../../../../../assets/img/advanced/medialuna.png" alt="">
    <img class="mediaLuna mediaLuna2" src="../../../../../assets/img/advanced/medialuna.png" alt="">
    <img class="lineas linea1" src="../../../../../assets/img/advanced/lineas.png" alt="">
    <img class="lineas linea2" src="../../../../../assets/img/advanced/lineas.png" alt="">
    <div class="circuloImg"></div>
    <div class="contenedorTexto">
        <h2 class="titulo">NIVEL AVANZADO C1 (LISTENING)</h2>
        <p class="texto">Identifica el Audio de Conversaciones, Canciones y Peliculas de manera natural</p>
        <a href="https://www.facebook.com/MobilEnglishGuadalajara/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook redes"></i></a>
    </div>
</div>