import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BegginerComponent } from './begginer/begginer.component';
import { IntermediateComponent } from './intermediate/intermediate.component';
import { NivelComponent } from './nivel.component';
import { AdvancedComponent } from './advanced/advanced.component';


export const routes: Routes = [
    { path: 'nivel', component: NivelComponent,
    children: [
      { path: 'begginer', component: BegginerComponent},
      { path: 'intermediate', component: IntermediateComponent },
      { path: 'advanced', component: AdvancedComponent },
      { path: '', pathMatch: 'full', redirectTo: 'begginer' }
    ]},
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class NivelRoutingModule { }