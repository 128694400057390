<div class="container-fluid" id="metodo">
    <section class="row metodologia">
        <div class="col-12">
            <img src="../../../assets/img/MOBILE-B3.png" alt="" class="tImageP mt-5 scrollflow -pop" >
        </div>
        <div class="col-12 pA">
            <div class="grupo">
                <img class="corazon" src="../../../../assets/img/grupo/corazon.png" alt="">
                <img class="tImage1" src="../../../assets/img/grupo/estudio.png" alt="">
                <img class="maceta" src="../../../../assets/img/grupo/maceta.png" alt="">
                <img class="planta planta1" src="../../../../assets/img/grupo/planta.png" alt="">
                <img class="planta planta2" src="../../../../assets/img/grupo/planta2.png" alt="">
                <img class="mensaje mensaje1" src="../../../../assets/img/grupo/mensaje1.png" alt="">
                <img class="mensaje mensaje2" src="../../../../assets/img/grupo/mensaje1.png" alt="">
                <img class="mensaje mensaje3" src="../../../../assets/img/grupo/mensaje2.png" alt="">
                <img class="mensaje mensaje4" src="../../../../assets/img/grupo/mensaje3.png" alt="">
                <img class="mensaje mensaje5" src="../../../../assets/img/grupo/mensaje3.png" alt="">
            </div>
            <div class="contenedor-texto-pA">
                <h3 class="titulo-pA">Cursos</h3>
                <div class="text-pA">
                    <button class="boton" (click) = "textAlert()">CONTACTANOS <i class="far fa-address-book"></i></button>
                    <br>CONTAMOS CON DIFERENTES HORARIOS, NIVELES Y MODALIDADES.
                    <br>PREGUNTA POR EL MEJOR PLAN PARA TI.
                    <ul>
                        <li>MATUTINO: 4HRS/SEMANA</li>
                        <li>VESPERTINO: 4HRS/SEMANA</li> 
                        <li>SABATINO: 4HRS/SEMANA</li>
                        <li>PERSONALIZADO: PLANEAREMOS TU HORARIO Y TUS CLASES A TU MEDIDA.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 text-justify pB " id="pb">
            <div class="contenedor-texto-pB">
                <h3 class="titulo-pB">Método</h3>
                <div class="text-pB"> PIENSA, HABLA E INTERACTÚA EN INGLÉS DESDE TU PRIMERA CLASE.
                    <br>PRACTICA CON LOS ELEMENTOS ESENCIALES DEL IDIOMA:
                    <ul>
                        <li>CONVERSACIÓN.</li>
                        <li>LECTURA. </li>
                        <li>ESCRITURA. </li>
                        <li>AUDIO Y GRAMÁTICA DIVIDIDOS EN 4 NIVELES.</li>
                    </ul>
                </div>
            </div>
            <div class="contenedorPizarra"> 
                <img class="pluma" src="../../../../assets/img/pluma.png" alt="">
                <img class="libro" src="../../../../assets/img/libro.png" alt="">
                <img class="tImage2" src="../../../assets/img/teacher.png" alt="" >
            </div>
        </div>
    </section>
</div>
